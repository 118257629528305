<template>
  <div id="app">
    <b-navbar v-if="isRouteNameValid" toggleable="lg" :variant="isRouteNameValid ? themes[$route.name].variant : 'info'" :type="isRouteNameValid ? themes[$route.name].type : 'light'" class="px-5">
      <b-navbar-brand to="#">
        <img :src="require(`@/assets/images/${$route.name}.png`)" alt="Logo" style="height: 80px;">
      </b-navbar-brand>
      <!--<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>-->
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="$route.name == 'home'">
          <b-nav-item to="ikco">IKCO</b-nav-item>
          <b-nav-item to="saipa">SAIPA</b-nav-item>
          <b-nav-item to="veniran">VENIRAN</b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-brand to="#">
            <!--<img src="@/assets/images/mision-transporte.png" alt="Logo" style="height: 80px;">-->
          </b-navbar-brand>
          <!-- <b-nav-item to="#catalogue">Catálogo</b-nav-item>
          <b-nav-item to="#form">Formulario</b-nav-item> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>
export default {
  title() { return "F&L Soluciones Integrales" },
  data() {
    return {
      themes: {
        ikco: {
          variant: 'primary',
          type: 'dark'
        },
        saipa: {
          variant: 'info',
          type: 'light'
        },
        veniran: {
          variant: 'secondary',
          type: 'light'
        },
        censoautomotriz: {
          variant: 'info',
          type: 'light'
        }
      }
    }
  },
  computed: {
    isRouteNameValid() {
      return ['ikco', 'saipa', 'veniran', 'censoautomotriz'].includes(this.$route.name)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
