import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import { Bar } from 'vue-chartjs/legacy'

// Bootstrap
import '@/assets/scss/_main.scss'

// Title
import titleMixin from './plugins/titleMixin'
Vue.mixin(titleMixin)

Vue.use(BootstrapVue)
Vue.use(Bar)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
